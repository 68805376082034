/**
 * 封装 axios 请求模块
 */
import axios from "axios"
// import {  } from ".";
const request = axios.create({
  // baseURL: "http://192.168.2.107" // 基础路径
  // baseURL: "https://api.da-mi.cn/"
  baseURL: window.server.SERVER_API
})

export default request